.Us-body {
    background-color: #282c34;
    min-height: 100vh;/*here we can add somje other type*/
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:top;/*it was center */
    font-size: calc(40px + 2vmin);
    color: white;
    background-image: url('../../../assets/background.png');
    z-index: -3;
    background-size: 100% 100%;
  }

  .Us-button {
      size: 4em;
      color: white;
  }

  .Us-message-list-body {
      color: black;
      min-width: width auto;;
  }
  
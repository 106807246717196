.App {
  text-align: center;
}
 
.App-logo {
  height: 14em;
  width: 12em;
  pointer-events: none;
  border-radius: 100%;
  object-fit: fill;

}

.App-project-card {
  width: 100%;
  height: 30;
  display: flex;
  flex-direction: column;
}


/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  background-color:white;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
  overflow: hidden;
}

.App-footer {
  background-color: white;
  min-height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  position: fixed;
  z-index: 10;
  bottom: 0;
  width: 100%;
}

.App-body {
  background-color: #282c34;
  min-height: fit-content;/*here we can add somje other type*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('../src/assets/background.png');
  padding-top: 4em;
  padding-bottom: 4em;
  z-index: -3;

}

.App-projects-body {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url('../src/assets/background.png');
  padding-top: 4em;
  padding-bottom: 4em;
  /* padding-left: 3em; */
  z-index: -3;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
